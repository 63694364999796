.outstanding_box {
  margin-top: 30px;
}

.outstanding_box_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}
