.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination {
    text-align: start!important;
    left: 100px!important;
    padding-bottom: 50px!important;
    padding-left: 300px!important;
}

.swiper-pagination-bullet {
    /* width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2); */
    width: 29px !important;
    height: 6px !important;
    border-radius: 6px !important;
    background: #21233D !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
    width: 58px !important;
    height: 6px !important;
    background: #05cfdc !important;
}
