.search-position-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-btn-search {
    border: 0 !important;
    border-radius: 0px 4px 4px 0px !important;
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}

.about-btn-search:hover {
    background: var(--button-2, linear-gradient(94deg, #1870f5 103%, #00afd6 10.5%));
}

.about-input-search {
    border: 0 !important;
    border-radius: 4px 0px 0px 4px !important;
    background: var(--White, #fff) !important;

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05) !important;
}

.about-input-search:hover {
    /* background: !important; */
}

.about-search-position-group {
    width: 70%;
}

.about-tag-container {
    padding: 4px 10px;
    border-radius: 4px;
    background: linear-gradient(94deg, rgba(24, 112, 245, 0.1) 9.29%, rgba(0, 175, 214, 0.1) 103.5%);
    font-size: 14px;
    cursor: pointer;
}

.about-tag-container.active {
    background: linear-gradient(94deg, #1870F5 9.29%, #00AFD6 103.5%);
    color: white;
}

.job-search-result-title {
    font-weight: 500;
    font-size: 20px;
}

.job-search-result-role {
    color: #828282;
}

.job-search-result-btn {
    cursor: pointer;
    font-weight: 600;
}

@media (max-width: 880px) {
    .job-search-result-title {
       
        font-size: 18px;
    }
}
