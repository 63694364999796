.react-tooltip {
    font-size: 18px !important;
    opacity: 1 !important;
    z-index: 1000;
}

.ccqm-session3-title {
    /* padding: 10px; */
    font-size: 32px;
    font-weight: 700;
    line-height: 48px; /* 133.333% */
    text-transform: uppercase;
    display: none;
}

.ccqm-session1-container-text {
    display: grid;
    gap: 30px;
    position: absolute;
    width: 589px;
    left: 15%;
    top: 10%;
}
.ccqm-session1-left-image {
}
.ccqm-detail-banner-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.ccqm-detail-banner-sub-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}
.ccqm-detail-left {
    background-size: cover;
    min-height: 100%;
    /* padding-left: 150px; */
}

.product-ccqm-card {
    display: grid;
    padding: 15px;

    gap: 20px;
    background: var(--White, #fff);

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}

.ccqm-algorithm-btn {
    padding: 12px 16px;
    background: #445260;
    color: white;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    border: 0;
}
.ccqm-algorithm-btn.active {
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
}

.ccqm-session3-container {
    padding-top: 200px;
    padding-bottom: 200px;
    position: relative;
    margin-bottom: 120px;
}
.product-session3-card {
    width: 249px;
    display: grid;
    gap: 5px;
}
.product-session3-card-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.tooltip-style {
    width: 490px !important;
    padding: 15px 30px !important;

    border-radius: 20px !important;
    background-color: #fff !important;
    color: #4f4f4f !important;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1) !important;
    /* background-color: rgb(0, 247, 255)!important */
}

#product-session3-card1 {
    position: absolute;
    top: 5%;
    left: 35%;
}
#product-session3-card2 {
    position: absolute;
    top: 3%;
    right: 32%;
}
#product-session3-card3 {
    position: absolute;
    top: 22%;
    right: 17%;
}
#product-session3-card4 {
    position: absolute;
    top: 48%;
    right: 15%;
}

#product-session3-card5 {
    position: absolute;
    bottom: 12%;
    right: 24%;
}

#product-session3-card6 {
    position: absolute;
    bottom: 5%;
    right: 42%;
}

#product-session3-card7 {
    position: absolute;
    bottom: 15%;
    left: 22%;
}
#product-session3-card8 {
    position: absolute;
    bottom: 38%;
    left: 13%;
}

#product-session3-card9 {
    position: absolute;
    top: 25%;
    left: 17%;
}
.ccqm-detail-banner-mb {
    display: none;
}
/* Mobile */
@media (max-width: 768px) {
    .ccqm-detail-banner {
        display: none;
    }
    .ccqm-detail-banner-mb {
        display: block;
        background-color: #F5FCFA;
    }

    .ccqm-session1-container-text {
        width: 100%;
        position: relative;
        display: grid;
        left: unset;
        top: unset;
        background-color: #F5FCFA;
    }

    .tooltip-style {
        display: none !important;
    }
    .ccqm-detail-right {
        display: none !important;
    }
    .ccqm-detail-left {
        /* padding-left: unset !important; */
        padding: 5% !important;
        width: 100%;
    }
    .ccqm-session3-title {
        display: block;
    }
    .ccqm-session3-container {
        padding-top: 30px;
        padding-bottom: 30px;
        display: grid;
        gap: 10px;
    }
    .product-session3-card {
        width: 100%;

        /* DS 2 */
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
        padding: 15px 30px;
        border-radius: 10px;
    }

    .product-ccqm-session3-image {
        display: none;
    }

    #product-session3-card1 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }
    #product-session3-card2 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }
    #product-session3-card3 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }
    #product-session3-card4 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }

    #product-session3-card5 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }

    #product-session3-card6 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }

    #product-session3-card7 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }
    #product-session3-card8 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }

    #product-session3-card9 {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
    }
}

/* 768 - 992 */
@media (max-width: 992px) and (min-width: 768px) {
    .react-tooltip {
        font-size: 12px !important;

    }

    .product-session3-card {
        width: 150px;
    }
    .product-session3-card-title {
        font-size: 14px;
        line-height: 20px
    }
    .product-session3-card-content {
        font-size: 12px;
        line-height: 16px
    }
    .product-ccqm-session3-image {
        width: 450px;
    }
    .ccqm-session3-container {
        padding-top: 150px;
        padding-bottom: 150px;
        position: relative;
    }

    #product-session3-card1 {
        position: absolute;
        top: 3%;
        left: 30%;
    }
    #product-session3-card2 {
        position: absolute;
        top: 1%;
        right: 25%;
    }
    #product-session3-card3 {
        position: absolute;
        top: 23%;
        right: 5%;
    }
    #product-session3-card4 {
        position: absolute;
        top: 48%;
        right: 2%;
    }

    #product-session3-card5 {
        position: absolute;
        bottom: 12%;
        right: 10%;
    }

    #product-session3-card6 {
        position: absolute;
        bottom: 2%;
        right: 38%;
    }

    #product-session3-card7 {
        position: absolute;
        bottom: 12%;
        left: 10%;
    }
    #product-session3-card8 {
        position: absolute;
        bottom: 38%;
        left: 5%;
    }

    #product-session3-card9 {
        position: absolute;
        top: 25%;
        left: 7%;
    }
}

/* 992 - 1200 */

@media (max-width: 1310px) and (min-width: 992px) {
    .react-tooltip {
        font-size: 12px !important;

    }

    .product-session3-card {
        width: 150px;
    }
    .product-session3-card-title {
        font-size: 14px;
        line-height: 20px
    }
    .product-session3-card-content {
        font-size: 12px;
        line-height: 16px
    }
    .product-ccqm-session3-image {
        width: 450px;
    }
    .ccqm-session3-container {
        padding-top: 150px;
        padding-bottom: 150px;
        position: relative;
    }

    #product-session3-card1 {
        position: absolute;
        top: 3%;
        left: 30%;
    }
    #product-session3-card2 {
        position: absolute;
        top: 1%;
        right: 25%;
    }
    #product-session3-card3 {
        position: absolute;
        top: 23%;
        right: 5%;
    }
    #product-session3-card4 {
        position: absolute;
        top: 48%;
        right: 2%;
    }

    #product-session3-card5 {
        position: absolute;
        bottom: 12%;
        right: 10%;
    }

    #product-session3-card6 {
        position: absolute;
        bottom: 2%;
        right: 38%;
    }

    #product-session3-card7 {
        position: absolute;
        bottom: 12%;
        left: 10%;
    }
    #product-session3-card8 {
        position: absolute;
        bottom: 38%;
        left: 5%;
    }

    #product-session3-card9 {
        position: absolute;
        top: 25%;
        left: 7%;
    }
}


@media (max-width: 1680px) and (min-width: 1200px) {
    .react-tooltip {
        font-size: 14px !important;

    }
    .ccqm-detail-left {
        padding-left: 20px !important;
    }

    .ccqm-session3-container {
        padding-top: 150px;
        padding-bottom: 150px;
        position: relative;
    }

    #product-session3-card1 {
        position: absolute;
        top: 3%;
        left: 33%;
    }
    #product-session3-card2 {
        position: absolute;
        top: 1%;
        right: 27%;
    }
    #product-session3-card3 {
        position: absolute;
        top: 23%;
        right: 7%;
    }
    #product-session3-card4 {
        position: absolute;
        top: 48%;
        right: 5%;
    }

    #product-session3-card5 {
        position: absolute;
        bottom: 10%;
        right: 15%;
    }

    #product-session3-card6 {
        position: absolute;
        bottom: 0%;
        right: 39%;
    }

    #product-session3-card7 {
        position: absolute;
        bottom: 8%;
        left: 18%;
    }
    #product-session3-card8 {
        position: absolute;
        bottom: 37%;
        left: 7%;
    }

    #product-session3-card9 {
        position: absolute;
        top: 20%;
        left: 13%;
    }
}

