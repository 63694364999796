.card_new {
  overflow: hidden;
}

.card_new_thumbnail {
  width: 100%;
  height: 236px;
  overflow: hidden;
  border-radius: 10px;
}

.card_new_thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_new_infor {
  width: calc(100% - 32px);
  margin: 0 auto;
  transform: translateY(-40px);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card_new_author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card_new_author div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card_new_xemchitiet {
  display: flex;
  align-items: center;
  gap: 5px;
}
.card_new_xemchitiet p {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
