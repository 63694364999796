.header_container {
  display: flex;
  /* padding: 0px 120px; */
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 2px 4px -30px rgba(33, 37, 41, 0.04);
}

.header_content {
  /* align-items: center; */
  flex-shrink: 0;
  color: #21233d !important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header_content {
    flex-shrink: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .header_content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-shrink: 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .header_content {
    display: flex;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
  }
}

.nav-link {
  /* color: #464749 !important; */
  font-weight: 500;
}

.dropdown-menu {
  --bs-dropdown-item-padding-y: 0.625rem;
  --bs-dropdown-link-active-bg: transparent;
  border-radius: 0px 0px 10px 10px;
  background: var(--White, #fff);

  /* DS 1 */
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05),
    0px 4px 16px 0px rgba(17, 17, 26, 0.05);
  padding: 15px;
  border: 0;
  /* gap: 20px;
    display: flex; */
}
.text-header {
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
  text-transform: uppercase;

  background: var(
    --button-2,
    linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-divider {
  background: rgba(229, 229, 229, 0.25);
  border-top: 0;
  height: 1px;
}

.header-link {
  color: #4f4f4f !important;
  font-weight: 500;
  text-decoration: none;
}

/* .header-item-active {
    border-bottom: 2px solid linear-gradient(94deg, #1870F5 9.29%, #00AFD6 103.5%);
} */

.header-item-active {
  position: relative;
}

.header-item-active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
}

@media (max-width: 820px) {
  .header-item-active::after {
    width: 10%;
    height: 2px;
  }

  .dropdown-menu {
    --bs-dropdown-item-padding-y: 0.325rem;
    --bs-dropdown-link-active-bg: transparent;
    border-radius: unset;
    background: var(--White, #fff);

    /* DS 1 */
    box-shadow: unset;
    padding: unset;
    /* border: 1px solid ; */
    /* gap: 20px;
        display: flex; */
  }
}

#navbarScrollingDropdown,
.nav-link {
  margin-top: 0;
}

.dropdown-menu > a {
  margin-top: 0;
}
