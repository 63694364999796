.container {
    .background {
        width: 100%;
    }

    position: relative;

    .section_one {
        /* position: absolute;
        left: 3%;
        right: 3%;
        top: 15%; */
        padding-top: 50px;
        padding-bottom: 50px;
        .left_container {
            display: grid;
            gap: 25px;
            .title {
                font-size: 54px;
                font-weight: 700;
                line-height: 64px;
                letter-spacing: -0.25px;
                color: rgba(33, 35, 61, 1);
            }

            .desc {
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0em;
                color: rgba(33, 35, 61, 1);
            }
        }
    }

    .section_two {
        padding-top: 70px;
        padding-bottom: 100px;

        .main_title {
            text-align: center;
            font-size: 45px;
            font-weight: 500;
            line-height: 52px;
            letter-spacing: 0em;

            padding-bottom: 50px;
        }
        .group {
            display: flex;
            align-items: center;
            gap: 20px;

            .image {
                width: 100px;
                height: 73px;
            }
            .sub_group {
                display: grid;
                gap: 10px;

                .title {
                    font-family: Roboto;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 60px;
                    letter-spacing: 0em;
                    color: rgba(33, 35, 61, 1);
                }
            }
        }
    }
}
