.system-banner-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    color: white;
}

.system-banner-text {
    color: white;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
}

.system-banner-left {
    width: 589px;
    position: absolute;
    top: 10%;
    left: 10%;
    display: grid;
    gap: 30px;
}

.system-session2-right-image {
    width: 70%;
}

.system-session2-title {
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
}

.system-session2-left {
    background-color: white;
    border-radius: 50%;
    width: 508px;
    height: 508px;
    margin: auto;
}

.system-session2-right {
    width: 400px;
    display: grid;
    gap: 20px;
}

.system-session2-text {
    color: rgba(33, 35, 61, 0.65);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.system-session3-title {
    color: #21233D;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    text-align: center;
}

.system-session3-sub-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.system-session3-card {
    display: grid;
    gap: 15px;
}
.system-banner-mb {
    display: none;
}

/* mobile" */
@media (max-width: 880px) {
    .system-banner-left {
        width: auto;
        position: relative;
        top: unset;
        left: unset;
        display: grid;
        gap: 30px;
        padding: 10px;
    }
    .system-banner-mb {
        display: block;
    }
    .system-banner {
        display: none;
    }
    .system-session3-image {
        display: none !important;
    }
}

/* 14" */
@media (max-width: 1280px) and (min-width: 880px) {
    .system-banner-left {
        width: 500px;
        position: absolute;
        top: 5%;
        left: 10%;
        display: grid;
        gap: 20px;
    }
}


.system-session3-card > div > ul {
    list-style-image: url('/public/custom-bullet.svg');
}