.about-product-card {
    display: flex;
    padding: 15px;
    flex-direction: column;
    /* justify-content: flex-end;
    align-items: flex-end; */
    gap: 10px;

    border-radius: 10px;
    background: var(--White, #fff);
    width: 354px;
    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);

    transition: all 0.2s ease;
}

.about-product-card:hover {
    transform: scale(1.1);
}

.about-product-image {
    position: relative;
    padding-left: 17%;
    padding-bottom: 10%;
    min-height: 700px;
}

.card-left {
    position: absolute;
    left: 0;
    top: 15%;
}

.card-right {
    position: absolute;
    right: 10%;
    top: 10%;
}

.card-bottom {
    position: absolute;
    right: 40%;
    bottom: 0%;
}

.about-product-card-title {
    font-size: 20px;
    font-weight: 500;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .card-left {
        position: relative;
        left: unset;
        top: unset;
    }

    .card-right {
        position: relative;
        right: unset;
        top: unset;
    }
    .card-bottom {
        position: relative;
        right: unset;
        bottom: unset;
    }


    .about-product-image {
        position: relative;
        padding-left: unset;
        display: grid;
        gap: 10px;
    }

    .about-product-card {
        width: 100%;
    }

}

@media (min-width: 992px) and (max-width: 1280px) {
    .card-left {
        position: absolute;
        left: 0;
        top: 10%;
    }

    .card-right {
        position: absolute;
        right: 5%;
        top: 10%;
    }

    .card-bottom {
        position: absolute;
        right: 40%;
        bottom: 0%;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .card-left {
        position: relative;
        left: 0;
        top: 00%;
    }

    .card-right {
        position: relative;
        right: 0%;
        top: 00%;
    }

    .card-bottom {
        position: relative;
        right: unset;
        bottom: unset;
    }


    .about-product-card {
        width: 100%;
    }

    .about-product-image {
        display: grid;
        gap: 10px;
        padding-left: unset;
    }

    .about-product-card-title {
        font-size: 17px;
        font-weight: 500;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .card-left {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
    }

    .card-right {
        position: absolute;
        right: 0%;
        top: 20%;
    }

    /* .about-product-image {
        position: relative;
         padding-left: unset;
         display: grid;
         gap: 10px;

    } */
}
