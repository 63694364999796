.boxTitle_page {
}

.boxTitle_page h2 {
  margin-bottom: 20px;
}
.boxTitle_page p {
  line-height: 1.5em;
}

@media (max-width: 768px) {
  .boxTitle_page {
    margin-top: 20px;
  }
}
