.display_large {
  font-size: 50px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.25px;
}

.headline_small {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.headline_medium {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
}

.title_medium {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

.title_large {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.text_regular {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.016em;
}

.text_center {
  text-align: center;
}

.color_primary {
  color: #21233d;
}

.color_white {
  color: #ffffff;
}

.color_gray3 {
  color: #828282;
}

.bg_primary {
  background-color: #21233d;
}

.bg_white {
  background-color: #ffffff;
}

.bg_gradient_blue100 {
  background: linear-gradient(180deg,
      rgba(24, 112, 245, 0.05) 0%,
      rgba(0, 175, 214, 0.05) 100%);
}

.bg_gradient_blue500 {
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
}

.gradient-text {
  background-image: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn_gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 4px;
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  transition: all 0.2s ease-in;
}

.btn_gradient:hover {
  background: linear-gradient(180deg, #2776ec 0%, #07bae2 100%);
}

.list_vertical {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .display_large {
    font-size: 36px;
    line-height: 44px;
  }

  .title_large {
    font-size: 18boxTitle_pagepx;
    font-weight: 700;
    line-height: 28px;
  }
}

@media (max-width: 520px) {}