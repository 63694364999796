.contentblock-container {
    display: grid;
    align-items: start;
    border-radius: 10px;
    background: var(--Black-White, #fff);

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
    border: 0;
    cursor: pointer;
    /* padding-left: 20px;
    padding-right: 20px; */
    height: 100%;
}
/* 
.contentblock-body {
   border-left: 12px solid linear-gradient(94deg, #1870F5 9.29%, #00AFD6 103.5%);
   border-left: 12px solid transparent;
  background: linear-gradient(94deg, #1870F5 9.29%, #00AFD6 103.5%);
  border-radius: 5px; 
} */

.contentblock-body {
    position: relative;
    text-align: justify;
}

.contentblock-body::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 100%;
    border-radius: 0px 4px 4px 0px;
    background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
}
.contentblock-container:hover {
    background-color: #21233d;
}
.contentblock-container:hover .contentblock-body {
    color: white !important;
}

.contentblock-container:hover .contentblock-sub-title {
    color: white !important;
}

.contentblock-container:hover .contentblock-body::before {
    background: #fff;
}
