.management-team-image {
    
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .management-team-image {
        display: flex;
        justify-content: center!important;
        
    }
}