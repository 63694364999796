
.breadcrumb-item {
    font-size: 14px!important;
    color: #828282!important;
}

.breadcrumb-item.active {
    color: #21233d!important;
}

