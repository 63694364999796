.jobdetail-title {
    padding: 16px 24px;
    border-radius: 4px;
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
    border: 0;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    color: white;
}

.jobdetail-desc-title {
    color: var(--Black-1, #21233D);

    /* Title/Title Large */
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 127.273% */
}

#job-detail > li::marker {
    /* border-radius: 2px; */
    color: #1870f5;
    /* color:  linear-gradient(94deg, #1870F5 9.29%, #00AFD6 103.5%); */
}

.jobdetail-hr {
    height: 1px;
    flex-shrink: 0;
    background: rgba(229, 229, 229, 0.45);
}
