.session1_container {
    position: relative;
}

.session1_card {
    position: absolute;
    top: 0%;
    width: 70%;
    transform: translateY(50%);
    /* top: 20%; */
    color: white;
    left: 5%;

    .title {
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        margin-bottom: 10px;
    }
}

@media (max-width: 1280px) {
    .session1_card {

        top: 10%;
        width: 80%;
        transform: unset;
        /* top: 20%; */
        color: white;
        left: 5%;
    
        .title {
            font-size: 28px;
            line-height: 30px;
        }
        .text {
            font-size: 14px;
            line-height: 20px;
        }
    }
}