.box_download {
  border-radius: 10px;
  /* padding: 20px 16px; */
  width: 384px;
  box-shadow: 0px 8px 32px 0px #11111a0d, 0px 4px 16px 0px #11111a0d;
  /* margin-top: 60px; */
}

.box_download_top {
  padding-top: 20px;
  padding-bottom: 6px;
}

.box_download_bottom {
  padding-top: 6px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.box_download_btn_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.box_download_btn_group button {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  /* height: 38px; */
  width: 130px;
}

.box_download_btn_group button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .box_download {
    width: 360px;
    /* padding: 0; */
  }
}
