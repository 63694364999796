.layout_page_blog {
  /* padding-top: 24px; */
}

.layout_page_blog_inner {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.layout_page_blog_inner_left {
  width: 70%;
}

.layout_page_blog_inner_right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: centerBoxRightRegister;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin-bottom: 30px;
}

.layout_page_blog_inner_right .layout_box_bottom {
  margin-top: 30px;
}

.layout_page_blog_mobile {
  display: none;
}

@media (max-width: 992px) {
  .layout_page_blog_inner {
    flex-direction: column;
  }

  .layout_page_blog_inner_left {
    width: 100%;
  }

  .layout_page_blog_inner_right {
    display: none;
  }

  .layout_page_blog_mobile {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .BoxRightRegister {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {}

@media (max-width: 520px) {}