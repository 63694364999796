.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header {
    position: relative;
    padding-left: 10px; /* Adjust this to change the width of the border */


    .title {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: #21233D;
    }
}

.header::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px; /* Adjust this to change the width of the border */

    background: linear-gradient(
        180deg,
        #1870f5 0%,
        #00afd6 100%
    ); /* Adjust this to change the colors of the gradient */
}
