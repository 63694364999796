.about-title {
    /* Display/Display Small */
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
}

.introduce-card {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    border-radius: 10px;
    background: var(--White, #fff);

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);

    height: 100%;
    transition: all 0.2s ease;
}

.introduce-card-title {
    font-size: 22px;
    font-weight: 500;
}

.introduce-card-image {
    width: 48px;
    height: 48px;
}

.introduce-card-text {
}

.introduce-card:hover {
    transform: scale(1.1);
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .introduct-card-container {
        display: grid;
        gap: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .introduce-card-title {
        font-size: 18px;
        font-weight: 500;
    }

    .introduce-card-image {
        width: 34px;
        height: 34px;
    }

    .introduce-card-text {
        font-size: 14px;
    }
}
