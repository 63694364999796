.ul {
    padding-left: 20px;
}
.carousel {
    display: block;
}
@media (max-width: 768px) {
    .carousel {
        display: none;
    }
}
