.root_container {
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
    display: grid;
    justify-content: center;
}

.title {
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;

    color: rgba(33, 35, 61, 1);
}

.title_container {
    /* width: 600px; */
    align-items:center;
}
