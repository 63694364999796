.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f7f7;
  }
  
  .coming-soon-content {
    text-align: center;
  }
  
  h1 {
    font-size: 4rem;
    color: #21233D;
  }
  
  p {
    /* font-size: 1.2rem; */
    color: #21233D;
  }
  