.partner-hr {
    height: 1px;
    flex-shrink: 0;
    background: #e5e5e5;
    width: 70%;
}

.partner-title {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .partner-title {
        font-size: 17px;

    }
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .partner-title {
        font-size: 24px;
    }
}
