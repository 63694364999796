.container {
    .title {
        font-size: 45px;
        font-weight: 500;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .left {
        display: grid;
        gap: 50px;
        .label {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            color: rgba(33, 35, 61, 1);
        }
    }

    .right {
        .right_title {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0em;
            color: rgba(33, 35, 61, 1);
        }

        .money_save {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
        }

        .money_profit {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
        }
    }
}
