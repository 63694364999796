.product-title {
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
}

.product-card-container {
    height: 100%;
    padding: 15px 15px 35px 15px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);

    position: relative;
    transition: all 0.2s ease;
}

.product-card-img {
    border-bottom: 2px solid #21233d;
    padding: 15px;
}

.product-card-btn {
    border: 0;
    color: white;
    padding: 10px 10px;

    border-radius: 10px;
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));

    font-size: 14px;
}
.product-card-title {
    text-align: center;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

/* .product-first-card {
    margin-top: -15px;
} */
.more-info-btn {
    position: absolute;
    opacity: 0;
    bottom: -7%;
    transform: translateX(50%);
    right: 50%;
    transition: all 0.2s ease;
}
.product-card-container:hover .more-info-btn {
    position: absolute;
    bottom: -7%;
    opacity: 1;
}

.product-card-container:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .product-card-container {
        height: unset;
    }
}
