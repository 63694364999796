.container {
    box-shadow: 0px 8px 24px 0px #959DA533;
    border-radius: 10px;
    cursor: pointer;
    .image {
        width: 100%;
        height: 100%;
        border-radius: 10px 0px 0px 10px;
    }
    .right_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
    
        .header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
    
            .topic {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
            }
    
            .date {
                font-size: 16px;
                line-height: 24px;
                color: #bdbdbd;
            }
        }
    
        .title {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #000000;
        }
        .content {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
    
            color: #828282;
        }
    }
    
}

.container:hover {
    box-shadow: 0px 8px 24px 0px #959DA5;
    transition: 0.3s ease-in-out;
    transform: scale(1.02);
}

.thumbnail {
    width: 100%;
    /* height: 200px; */
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}