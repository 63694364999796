.sidebar-container-mb {
    display: none;
}



/* .breadcrumb-item {
    font-size: 14px!important;
    color: #828282;
}
.breadcrumb-item > a {
    margin-top: 0;
    font-size: 14px!important;
    color: #828282;
}
.breadcrumb-item.active {
    color: #21233d;
}

.breadcumb-custom {
    --bs-breadcrumb-divider: ">";
} */
.side-item {
    padding: 15px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    cursor: pointer;
    border-bottom: 1px solid rgba(229, 229, 229, 0.45);
}

.side-item.active {
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side-item.active {
    position: relative;
}

.side-item.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    border-radius: 0px 4px 4px 0px;
    background: #1870f5;
}

.side-item:hover {
    background-color: aliceblue;
}


@media (max-width: 768px) {
    .sidebar-container {
        display: none;
    }
    .sidebar-container-mb {
        display: block;
    }
    
}