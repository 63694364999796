.container {
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .topic {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .date {
        font-size: 16px;
        line-height: 24px;
        color: #bdbdbd;
    }
}

.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
}



@media (max-width: 768px){
    .title {
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
    }
    
}