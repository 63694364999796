/* Banner */

.banner_blog {
  margin-top: 84px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 19px;
  height: 549px;
  overflow: hidden;
}

.banner_blog_box_image {
  width: 588px;
  height: 440px;
}

.banner_blog_image {
  width: 588px;
  /* height: 440px; */
  overflow: hidden;
}
.banner_blog_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner_blog_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}

.blog_caigiaynhi {
  height: 383px;
  background: #f3f9fe;
}

.banner_new {
  display: flex;
  gap: 25px;
}

@media (max-width: 992px) {
  .banner_blog {
    margin-top: 20px;
    padding-top: 0;
  }
  .banner_blog_form {
    display: none;
  }
  .banner_blog_box_image {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .banner_blog_image {
    width: 100%;
    height: 400px;
  }
}
@media (max-width: 768px) {
  .blog_caigiaynhi {
    height: 200px;
  }
}
@media (max-width: 520px) {
  .banner_blog_image {
    width: 100%;
    height: 300px;
  }
  .banner_blog {
    height: 420px;
  }
  .blog_caigiaynhi {
    height: 150px;
  }
}
