.dm_2_list {
  margin: 30px 0;
}

.dm_2_banner div {
  height: 600px;
  width: 100%;
  margin: 30px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1440px) {
  .dm_2_banner div {
    height: 466px;
  }
}
@media (max-width: 1320px) {
  .dm_2_banner div {
    height: 400px;
  }
}
@media (max-width: 1100px) {
  .dm_2_banner div {
    height: 360px;
  }
}
@media (max-width: 992px) {
  .dm_2_banner div {
    height: 300px;
  }
}
@media (max-width: 830px) {
  .dm_2_banner div {
    height: 240px;
  }
}
@media (max-width: 768px) {
  .dm_2_banner div {
    height: 180px;
  }
}
@media (max-width: 520px) {
  .dm_2_banner div {
    height: 130px;
  }
}
