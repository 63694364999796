
.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .title {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: #21233D;
    }
}

.filter_topic {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    
}
.filter_topic_item {
    padding: 10px 24px 10px 24px;
    border: 1px solid var(--Grey-5, #E0E0E0);
    border-radius: 99px;
    transition: background-color 0.5s ease, color 0.5s ease;
    cursor: pointer;
    /*  */

    font-size: 16px;
    line-height: 24px;
    color: #21233D;
    &:hover {
        background-color: #21233D;
        color: white
    }
    

}
.filter_topic_item_selected {
    padding: 10px 24px 10px 24px;
    border: 1px solid var(--Grey-5, #E0E0E0);
    border-radius: 99px;
    transition: background-color 0.5s ease, color 0.5s ease;
    cursor: pointer;
    /*  */

    font-size: 16px;
    line-height: 24px;
    background-color: #21233D;
    color: white;

}

@media (max-width: 992px){
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
        }
    }

    .filter_topic_item {
        padding: 5px 12px;

        /*  */
    
        font-size: 16px;
        line-height: 24px;
    }
}