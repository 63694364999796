.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    width: 250px;

    position: relative;
    padding: 16px 24px 16px 24px;
    box-sizing: border-box;

    color: #fff;
    background: #fff;
    background-clip: padding-box; /* !importanté */
    border: 1 solid transparent; /* !importanté */

    cursor: pointer;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -1px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
    }
}

.container:hover {
    background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
    border: 1 solid transparent;
    transform: scale(1.02);
    .text {
        color: #fff;
        transition: color 0.5s ease;
    }
}

.icon {
    width: 14px;
    height: 14px;
}
