.group_box_navigate_black {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.group_box_navigate_black_item {
  height: 145px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.group_box_navigate_black_item:active {
  transform: scale(0.95);
}

.financial_parameters_page_circle_box {
  width: 100%;
  height: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.financial_parameters_page_circle {
  width: 271px;
  height: 271px;
  background: #d9d9d9;
  border-radius: 50%;
}


@media (max-width: 768px) {
  .group_box_navigate_black {
    grid-template-columns: repeat(2, 1fr);

  }

  .group_box_navigate_black_item {
    padding: 8px;
  }
}