.blog_page {
  padding-bottom: 100px;
}

.nav_blog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  flex-wrap: wrap;
}

.nav_blog_inner {
  height: 64px;
}

.nav_blog_inner ul {
  display: flex;
  align-items: center;
  gap: 45px;
}
.form_mobile {
  display: none;
}
@media (max-width: 992px) {
  .nav_blog_inner ul li a {
    font-size: 14px;
  }
  .form_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .nav_blog_inner ul {
    gap: 10px;
  }
  .nav_blog_inner ul li a {
    font-size: 13px;
  }
}
@media (max-width: 520px) {
  .nav_blog {
    box-shadow: 0px 8px 32px 0px #11111a0d, 0px 4px 16px 0px #11111a0d;
  }
  .nav_blog_inner ul li a {
    /* padding-bottom: 10px; */
    margin-top: 15px;
  }
}
