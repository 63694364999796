.root_container {
    /* background: rgba(255, 255, 255, 1); */

    display: grid;
    gap: 15px;
}
.header {
    display: grid;
    gap: 15px;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    .title {
        font-size: 45px;
        font-weight: 500;
        line-height: 52px;
    }
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    /* color: rgba(33, 35, 61, 1); */
}


.title_toggle {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(33, 35, 61, 1);
}

.icon {
    width: 32px;
    height: 32px;
}

@media (max-width: 820px) {
    .header {
        padding-left: unset;
        padding-right: unset;
        .title {
            font-size: 30px;
            font-weight: 500;
            line-height: 40px;
        }
    }

    .title {
        font-size: 18px;
        line-height: 24px;
    }
    
    
    .title_toggle {
        font-size: 18px;
        line-height: 24px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }
}
.collapse_container {
    /* padding: 30px 28px 30px 28px;
    border-radius: 20px 20px 0px 0px;
    

    box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05); */
}

.sub_title_toggle {
    padding: 6px 10px 6px 10px;
    border-radius: 4px;
    gap: 10px;
    color: inherit;
    background-color: rgba(249, 249, 251, 1);

    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
}

.collapse_header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    /* background: rgba(207, 226, 255, 1); */
    padding: 30px 28px 30px 28px;
    border-radius: 20px 20px 0px 0px;

    /* background: rgba(207, 226, 255, 1); */

    box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);
}

.collapse_content {
    padding: 10px 28px 10px 28px;
    border-radius: 0px 0px 20px 20px;

    box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);
    background: rgba(255, 255, 255, 1);
}

.active {
    background: rgba(207, 226, 255, 1);
}

.collapsed {
}
