.product-card-container {
    cursor: pointer;
}


.product-card-tag {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 233.333% */
    text-transform: uppercase;
    cursor: pointer;
}

.product-card-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .product-card-container {
        border-radius: 10px;
        border: 1px dashed #e5e5e5;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 1200px) and (min-width: 992px)   {
    .product-card-container {
        border-radius: 10px;
        border: 1px dashed #e5e5e5;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    
}
