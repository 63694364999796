.register_box {
  border-radius: 10px;
  width: 384px;
  padding: 20px 16px;
}

.register_box_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  width: auto;
}

.register_box_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
}

.register_box_form input {
  width: 100%;
  border-radius: 4px;
  padding: 10px 16px;
}

.btn_register_box {
  width: 100%;
}

.qr_box {
  width: 276px;
  height: 277px;
}

.qr_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .register_box {
    width: 360px;
    /* padding: 0; */
  }
}

@media (max-width: 992px) {
  .register_box {
    width: 360px;
    /* padding: 0; */
  }
}