@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

#root {
  font-family: Roboto;
}

.primary-color {
  background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

/* p {
  font-size: 17px!important;
} */

ul {
  padding: 0;
}

ul li {
  list-style-type: none;
}

p {
  margin: 0;
  color: #21233d;
}

span {
  color: #21233d;
}

button {
  outline: none;
  border: none;
}

button:active {
  transform: scale(0.97);
}

input {
  outline: none;
}
