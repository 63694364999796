.rich_text img {
  width: 100%;
}

@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .display_large {
    font-size: 36px;
    line-height: 44px;
  }
}
@media (max-width: 520px) {
}
