.about-form-container {
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    /* align-items: center; */
    gap: 30px;

    border-radius: 4px;
    border: 1px dashed #e5e5e5;
    width: 500px;
}
.about-form-label {
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

.about-form-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--Grey-5, #e0e0e0);
}

.about-form-submit {
    display: flex;

    padding: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;

    border-radius: 4px;
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .about-form-container {
        gap: 10px;

        width: 100%;
    }
}
