.about-partner-card {
    display: flex;
    /* width: 300px; */
    padding: 20px;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: var(--White, #fff);
    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);

    transition: all 0.2s ease;
}

.about-partner-card:hover {
    transform: scale(1.1);
}

.about-partner-card.card-1 {
    position: absolute;
    top: 10%;
    left: 10%;
}
.about-partner-card.card-2 {
    position: absolute;
    top: 20%;
    right: 15%;
}
.about-partner-card.card-3 {
    position: absolute;
    bottom: 0;
    left: 25%;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 880px) {
    .about-partner-bg {
        background-image: unset !important;
        display: grid;
        gap: 10px;
    }
    .about-partner-card {
        width: 100%;
    }

    .about-partner-card.card-1 {
        position: relative;
        top: unset;
        left: unset;
    }
    .about-partner-card.card-2 {
        position: relative;
        top: unset;
        right: unset;
    }
    .about-partner-card.card-3 {
        position: relative;
        bottom: unset;
        left: unset;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .about-partner-card.card-2 {
        position: absolute;
        top: 20%;
        right: -5%;
    }
}
