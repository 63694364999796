.container {
    border-radius: 10px;
    cursor: pointer;
    width: 100%;

    .image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .right_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        padding: 15px;

        .group {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
        }

        .title {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #000000;
        }

        .footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            color: #828282;
            .footer_text {
                font-size: 14px;
                line-height: 16px;
                color: #828282;
            }
        }
    }
}


/* Larger screens (e.g., desktops) */
@media (max-width: 1334px) and (min-width: 1200px) {
    .container {
        .right_container {
            padding: 5px;
        }
    }
}

.container:hover {
    box-shadow: 0px 8px 24px 0px #959da5;

    transition: 0.3s ease-in-out;
    transform: scale(1.02);
}

.icon {
    width: 18px;
    height: 18px;
}
