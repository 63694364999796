.home-container{
    display: grid;
    gap: 70px;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width: 820px) {
    .home-container{
        display: grid;
        gap: 70px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}
