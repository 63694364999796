#term-of-use > ul > li > .nav-link.active {
    background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    p {
        
    }
}

#term-of-use > p {
    font-size: 17px  ;
}

