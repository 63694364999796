.container {
    
    .banner_container {
        padding: 90px;
        .left {
            height: 100%;
            padding: 60px 45px 60px 45px;
            border-radius: 10px;
            gap: 10px;
            background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
            .title,
            .text {
                font-size: 32px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0em;
                color: rgba(255, 255, 255, 1);
            }

            .text {
                font-size: 17px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            gap: 20px;

            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

            box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);

            .title,
            .text {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;

                color: rgba(33, 35, 61, 1);
            }

            .text {
                font-size: 17px;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}
.container {
    
    .banner_container {
        padding: 90px;
        .left {
            height: 100%;
            padding: 60px 45px 60px 45px;
            border-radius: 10px;
            gap: 10px;
            background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
            .title,
            .text {
                font-size: 32px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0em;
                color: rgba(255, 255, 255, 1);
            }

            .text {
                font-size: 17px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            gap: 20px;

            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

            box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);

            .title,
            .text {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;

                color: rgba(33, 35, 61, 1);
            }

            .text {
                font-size: 17px;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}
