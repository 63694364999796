.featured_article {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  padding-top: 40px;
}

.featured_article_left {
  flex: 2;
}
.featured_article_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 992px) {
  .featured_article {
    flex-direction: column;
    gap: 30px;
  }
  .featured_article_left {
    flex: 1;
    width: 100%;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 520px) {
}
