.group_box_navigate_white {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.group_box_navigate_white_item {
  height: 145px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 8px 32px 0px #11111a0d, 0px 4px 16px 0px #11111a0d;
  cursor: pointer;
  overflow: hidden;
  text-wrap: wrap;

}

.group_box_navigate_white_item p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.group_box_navigate_white_item::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  height: 6px;
  width: 70px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.group_box_navigate_white_item:active {
  transform: scale(0.95);
}

.financial_parameters_page_circle_box {
  width: 100%;
  height: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.financial_parameters_page_circle {
  width: 271px;
  height: 271px;
  background: #d9d9d9;
  border-radius: 50%;
}


@media (max-width: 768px) {
  .group_box_navigate_white {
    grid-template-columns: repeat(2, 1fr);
   
  }
}