.news_page {
  padding-bottom: 100px;
}

.news_page_banner div {
  width: 100%;
  height: 465px;
  margin: 70px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.list_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 33px;
}

.list_new_title {
  margin: 0;
  position: relative;
}

.list_new_title::after {
  content: "";
  position: absolute;
  width: 85px;
  height: 6px;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  border-radius: 99px;
}

.list_new_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 629px;
}

.list_new_form_inpput {
  flex: 1;
  background: #f2f4f8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  padding: 0 10px;
}

.list_new_form_inpput input {
  width: 100%;
  height: 100%;
  padding: 14px 10px;
  border: none;
  background: #f2f4f8;
}

.list_new_form_inpput input::placeholder {
  color: #bdbdbd;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.list_new_form_btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 14px 16px;
  background: #21233d;
  color: #ffffff;
  border-radius: 10px;
}

.list_new_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
}
@media (max-width: 992px) {
  .news_page_banner div {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .news_page_banner div {
    height: 200px;
  }
  .list_new_form {
    width: auto;
  }
  .list_new_list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 520px) {
  .news_page_banner div {
    height: 150px;
  }
  .list_new_form {
    flex-direction: column;
  }
  .list_new_list {
    grid-template-columns: repeat(1, 1fr);
  }
}
