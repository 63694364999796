.classify {
  margin-top: 25px;
  min-height: 300px;
}

.classify_list {
  margin-top: 20px;
  margin-bottom: 173px;
}

.tab_views {
  margin-bottom: 20px;
}

.tab_views ul {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tab_views ul li {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 10px;
  background-color: #f5f7fb;
  border-radius: 6px;
}

.tab_views_active {
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  color: #ffffff;
}

.classify_list_inner {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
