.footer_blog {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 50px;
  gap: 50px;
}

.footerBlog_list {
  width: 254px;
}

.footer_blog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_blog_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.0024em;
  margin-bottom: 20px;
}

.itePath_title {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: #4f4f4f;
  cursor: pointer;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  flex: 1;
}

.itePath_box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.itePath_box a {
  flex: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.footer_blog_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list_icon {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.list_icon span {
  cursor: pointer;
}

.footer_blog_box_logo {
  width: 239px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer_blog_box_logo p {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}

.footer_blog_logo {
  width: 185px;
  overflow: hidden;
}

.footer_blog_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box {
  display: flex;
  gap: 15px;
}

.box_qr {
  width: 92px;
  overflow: hidden;
}

.box_store {
  width: 130px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .footer_blog {
    flex-wrap: wrap;
    gap: 30px;
  }
  .footerBlog_list_flex {
    flex: 1;
    width: 100%;
  }
  .footer_blog_box_logo {
    width: 150px;
  }
  .footer_blog_title {
    font-size: 18px;
  }
  .footerBlog_list {
    width: 150px;
  }
  .footer_blog_logo {
    width: 160px;
  }
}

@media (max-width: 768px) {
  .footer_blog_box_logo {
    width: 300px;
  }
  .itePath_box {
    width: 200px;
  }
  .footer_blog_box {
    gap: 10px;
  }
  .footer_blog_title {
    margin-bottom: 10px;
  }
}

@media (max-width: 520px) {
}
