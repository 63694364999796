.about-slogan-text {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px; /* 125% */
}

.about-slogan-text span {
    background: var(--button-2, linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-slogan-session2 {
    display: block;
}
.about-slogan-session2-mb {
    display: none;
}

.about-slogan-session3 {
    margin-top: -5%;
    margin-left: 10%;
}

.slogon-session3-container {
    margin-top: 15px;
    display: grid;
    justify-content: center;
    gap: 30px;
    .title {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }

    .text {
        text-align: justify;
    }
}

.slogon-session3-header {
    display: grid;
    place-content: center;
    gap: 15px;
}
@media (max-width: 820px) {
    .about-slogan-session2-mb {
        display: block;
    }
    .about-slogan-session2 {
        display: none;
    }
    .about-slogan-session3 {
        margin-top: unset;
        margin-left: unset;
    }
}
