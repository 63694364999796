.card_blog {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
}

.card_blog_thumbnail {
  border-radius: 10px;
  overflow: hidden;
  height: 383px;
}

.card_blog_thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_blog_infor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card_blog_title h2 {
  margin: 0;
}
.card_blog_category {
  padding-left: 10px;
  position: relative;
}
.card_blog_category::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;

  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
}
.card_blog_category p {
  font-weight: 700;
}

.card_blog_author {
  display: flex;
  gap: 15px;
}

.card_blog_author div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.card_blog_small {
  margin-top: 0;
  display: flex;
  gap: 10px;
  margin-top: 0;
}

.card_blog_thumbnail_small {
  /* flex: 1; */
  width: 187px;
  height: 126px;
}

.card_blog_thumbnail_small img {
  object-fit: cover;
}

.card_blog_title p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card_blog_category_small p {
  font-size: 14px;
  font-weight: 600;
}

.card_blog_infor_small {
  /* width: 49%; */
  gap: 8px;
  width: 100%;
  flex: 1;
}

.card_blog_infor_small .card_blog_title h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.card_blog_infor_small .card_blog_description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.card_blog_infor_small .card_blog_author p {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.card_blog_title h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.card_blog_description p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 520px) {
  .card_blog_thumbnail_small {
    width: 130px;
  }
}
