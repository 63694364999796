.root_container {
    margin-top: 3%;
    font-family: "roboto";
}

.title {
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
}

.alphabet_container {
    /* display: flex; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
}

.alphabet_item {
    font-size: 17px;
    font-weight: 700;
    line-height: 30px;
    /* color: rgba(130, 130, 130, 1); */
    padding: 10px 20px;
    cursor: pointer;
}

.active {
    font-weight: 700;
    background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.terminology_container {
    display: grid;
    gap: 10px;
    text-align: start;
}

.terminology_title {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 700;
    line-height: 30px;
}
@media (max-width: 768px) {
    .alphabet_item {
        /* color: rgba(130, 130, 130, 1); */
        padding: 10px;
    }
}

@media (max-width: 820px) {
    .alphabet_item {
        /* color: rgba(130, 130, 130, 1); */
        padding: 15px;
    }

    .alphabet_container {
        padding-left: unset;
        padding-right: unset;
    }
}
