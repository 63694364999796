.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
